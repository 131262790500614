@import "../global.scss";

.topbar{
    width: 100%;
    height: 70px;
    background-color: white;
    color: $mainColor;
    position: fixed;
    top: 0;
    z-index: 3;
    transition: all 1s ease;

    .wrapper{
        padding: 10px 30px;
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
    .left{

        display: flex;
        align-items: center;
        a{
            text-decoration: none;
            color: inherit;
        }
        .logo{
            font-size: 40px;
            font-weight: 700;
            text-decoration: none;
            color:inherit;
            margin-right: 40px;
            @include mobile{
                font-size: 30px;
            }
        }

        .itemContainer{
            display: flex;
            align-items: center;
            margin-left: 30px;
            @include mobile{
                display: none;
            }

    

            .icon{
                font-size: 18px;
                margin-right: 5px
            }
            span{
                font-size: 15px;
                font-weight: 700px;
            }
        }
    }
    .right{
        overflow: hidden;

        .hamburger{
            width: 32px;
            height: 25px;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            cursor: pointer;
            z-index: 3;
            overflow: hidden;


            span{
                width: 64px;
                height:3px;
                background-color:  $mainColor;
                transform-origin: left;
                transition: all 2s ease;
                overflow: hidden;

            }
        }
    }

    &.active{
        background-color: $mainColor;
        color:white;
        overflow: hidden;

        .hamburger{
            span{
                &:first-child{
                    background-color: white;
                    transform:rotate(45deg);
                    overflow: hidden;

                }
                &:nth-child(2){
                    opacity: 0;
                    overflow: hidden;

                }
                &:last-child{
                    background-color: white;
                    transform:rotate(-45deg);


                }
            }
        }
    }

}