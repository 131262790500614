@import "../global.scss";

.skillMain{
    width: 100%;
    height: 100%;
    text-align: center;
    display: table-cell;
    background-color: white;
    h1{
    color: black;
    margin-top: 5px;
    overflow: hidden;
    }
    .container{
        display: inline-block;
        background-color: #a1aba5;
        vertical-align: middle;
        width: 70%;
        height: 80%;
        margin-top: 10px;
        border-radius: 20px;
        overflow: visible;
        
        @include mobile{
            width: 95%;
            h2{
                font-size: 17px;
            }
        }  



        .left{
            li{
                overflow: hidden;
            }
            width: 40%;
            float: left;
            padding: 20px;
            border-radius: 20px;
            background-color: #98c5d4;
            margin-left: 50px;
            margin-top: 5px;
            overflow: visible;
            color: #676b6a;
            h3{
                margin-top: 20px;
                text-align: left;
                font-size: 20px;
                color: #676b6a;

                @include mobile{
                    font-size: 12px;
                }  
                .icon{
                    display: inline;
                    vertical-align: bottom;
                    color: #676b6a;

                    @include mobile{
                        font-size: 12px;
                    }
                }
            }
            @include mobile{
                margin-left: 5px;
                width: 35%;
            }

            h3{
                margin-top: 10px;
                text-align: left;
                font-size: 20px;
                color: #676b6a;

                @include mobile{
                    font-size: 12px;
                }  
                .icon{
                    display: inline;
                    vertical-align: bottom;
                    color: #676b6a;

                    @include mobile{
                        font-size: 12px;
                    }
                }
            }

        }

        .right{
            li{
                overflow: hidden;
            }
            width: 40%;
            float: right;
            padding: 20px;
            background-color: #98c5d4;
            border-radius: 20px;
            margin-right: 50px;
            margin-top: 10px;
            overflow: hidden;
            color: #676b6a;

            @include mobile{
                margin-right: 5px;
                width: 35%;
            }

            h3{
                margin-top: 12px;
                text-align: left;
                font-size: 20px;
                color: #676b6a;
                @include mobile{
                    font-size: 12px;
                }  
                .icon{
                    display: inline;
                    vertical-align: bottom;
                    overflow: hidden;
                    color: #676b6a;
                    @include mobile{
                        font-size: 12px;
                    }
                }
            }
        }

        
    }
    .play{
        overflow: hidden;
        a{
            bottom: 0px;
            vertical-align: middle;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            text-decoration: none;
            color: black;
            left: 25%;
            overflow: hidden;
            margin-top: 11px;
            @include mobile{
                margin-top: 3px;
            }
    
            img{
                width: 90px;
                overflow: hidden;
            }
    
    
            li{
                list-style: none;
                overflow: hidden;
            }
        }
    }

    
}