@import "../global.scss";

.intro{
    //background-color:#78877e;
    background-color: white;
    display: flex;


    @include mobile{
        flex-direction: column;
        align-items: center;
    }
    .left{
        flex: .5;
        overflow: hidden;

        .imgContainer{
            width: 700px;
            height: 700px;
            border-radius: 50%;
            display: flex;
            align-items: flex;
            justify-content: center;
            @include mobile{
                img{
                height:50%;
                }
            }
        }
    }

    .right{
        flex: .5;
        position: relative;
    

    .wrapper{
        width: 100%;
        height: 100%;
        padding-left: 50px;
        display: flex;
        flex-direction: column;
        justify-content: center;

        @include mobile{
        padding-left: 0px;
        align-items: center;
        }

        h1{
            font-size: 60px;
            margin: 10px 0;

            @include mobile{
                font-size: 40px;
            }

        }
        h2{
            font-size: 35px;

        }
        .about{
            margin-top: 55px;
            font-weight: 300;
            font-size: 20px;
            @include mobile{
                font-size: 15px;
                align-items: center;
                margin-top: 10px;
                margin-bottom: 70px;
            }
        }
        h3{
            font-size: 30px;

            @include mobile{
                font-size: 20px;
            }

            span{
                font-size: inherit;
                color: lightskyblue;

            }

            .ityped-cursor{
                animation: blink 1.5s infinite;
            }

            @keyframes blink {
                50%{
                    opacity: 1;
                }
                100%{
                    opacity:0;
                }
            }
        }
    }
    a{
        position: absolute;
        bottom: 10px;
        vertical-align: middle;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        text-decoration: none;
        color: black;
        left: 25%;
        @include mobile{
            left:40%;
        }

        img{
            width: 90px;
        }


        li{
            list-style: none;
        }
    }
}
}