@import "../global.scss";

.playdiv{
    width: 100%;
    height: 100%;
    background-color: white;
    text-align: center;
    position: relative;

    .gamebox{
        width: 70%;
        height: 70%;
        border-style: solid;
        border-color: black;
        align-items: center;
        display: inline-block;
        margin-top: 25px;
        animation-duration: 1s;
        overflow: visible;
        position: relative;
       background-color: #0078f9;
        color: white;
        border-radius: 12px;
        @include mobile {
        width: 95%;
        height: 70%;
        }
        .instructions{
            margin-top: 40px;
            bottom: 0px;
            display: inline-block;
            @include mobile{
                margin-top: 40px;

            }    
            h2{
                text-align: left;
                font-size: 25px;
                @include mobile{
                    font-size: 20px;

                }                
            }
            h3{
                margin-left: 20px;
                text-align: center;
                font-size: 20px;
                margin-right: 20px;
                @include mobile{
                    font-size: 15px;
                }  
            }
        }
        .scoreTimer{
            .score{
                float: left;
                margin-left: 60px;
                @include mobile{
                    margin-left: 20px;
    
                }
            }
            .timer{
                float:right;
                margin-right: 60px;
                @include mobile{
                    margin-right: 20px;
    
                }
            }
            @keyframes wrong{
                0%{color: black;}
                50%{color: red;}
                100%{color: black;}
            }
            
            @keyframes correct{
                0%{background-color: white;}
                50%{background-color: green;}
                100%{background-color: white;}
            }
        }
        .submitBlocks{
            margin-top: 50px;
            margin-left: 50px;
            margin-right: 50px;
            align-items: center;
            display: inline-block;
            @include mobile{
                margin-left: 5px;
            margin-right: 5px;
            overflow: visible;

            }  
            .submit{
                height: 70px;
                width: 70px;
                border-style: solid;
                float: left;
                text-align: center;
                background-color: #ebbc72;
                border-radius: 10px;
                vertical-align: middle;
                display: flex;
                justify-content: center;
                align-items: center;
                text-align: center;
                margin-left: 12px;
                margin-right: 12px;
                cursor: pointer;
                overflow: visible;
                color: white;

                @include mobile{
                    margin-left: 3px;
                    margin-right: 3px;
                    height: 30px;
                width: 30px;
                }  
            }
            .Btn{
                float: left;
                vertical-align: middle;
                display: flex;
                justify-content: center;
                align-items: center;
                text-align: center;
                overflow: visible;
                border-radius: 5px;

                @include mobile {
                    width: 45px;
                }
            }

        }

        .playBlocks{
            margin-top: 50px;
            margin-left: 50px;
            margin-right: 50px;
            align-items: center;
            display: inline-block;
            overflow: visible;

            .play{
                height: 70px;
                width: 70px;
                border-style: solid;
                float: left;
                text-align: center;
                background-color: #ebbc72;
                border-radius: 10px;
                vertical-align: middle;
                display: flex;
                justify-content: center;
                align-items: center;
                text-align: center;
                margin-left: 12px;
                margin-right: 12px;
                cursor: pointer;
                overflow: visible;
                color: white;


                @include mobile{
                    margin-left: 3px;
                    margin-right: 3px;
                    height: 30px;
                width: 30px;
                }
            }

        }
        .copyright{
            position: absolute;
            bottom: 0px;
            right: 0px;
            float: right;
            .created{
                float: right;
                display: inline;
                margin-right: 5px;
                .git{
                    display: inline;
                    vertical-align: bottom;
                    margin-right: 3px;
                }
                a{
                    font-size: inherit;
                    color: inherit;
                    text-decoration: none;
                
                }
                vertical-align: middle;
                align-items: center;
            }

        }
    }
    .contact{
        overflow: hidden;
        position: absolute;
        bottom: 0;
        left: 48%;
        @include mobile{
                left:44%

        }
        a{
            bottom: 0px;
            vertical-align: middle;
            align-items: center;
            justify-content: center;
            text-decoration: none;
            color: black;
            left: 25%;
            overflow: visible;
            margin-top: 11px;
            align-items: center;
            display: inline-block;
            
    
            img{
                width: 90px;
                overflow: hidden;
            }
    
    
            li{
                list-style: none;
                overflow: hidden;
            }
        }
    }
    
}
