//$mainColor: #15023a;
$mainColor: rgb(101, 188, 223);


$width:1366px;

@mixin mobile{
    @media (max-width: #{$width}){
        @content
    }
}