@import "../global.scss";

.portfolio{
    background-color: white;
    //background-color:#78877e;

    display: flex;
    flex-direction: column;
    align-items: center;

    h1{
        font-size: 50px;
        overflow: hidden;
        @include mobile{
            font-size: 20px;
        }
    }

    ul{
        margin:10px;
        padding: 0;
        list-style: none;
        display: flex;
        overflow: hidden;

        @include mobile{
            flex-wrap: wrap;
            justify-content: center;
        }
        li{
            font-size: 14px;
            margin-right: 50px;
            padding: 7px;
            border-radius: 10px;
            cursor: pointer;
            overflow: hidden;
            @include mobile{
                font-size: 10px;
                padding: 3px;
            }
        
            &.active{
                background-color: $mainColor;
                color:white;
            }
        }

    }

    .squareplay{
        width: 70%;
        height:70%;
        display: flex;
        flex-direction: row;
        padding: 30px;
        color:white;        ;
        align-content: center;
        position: relative;
        overflow:visible;

        @include mobile{
            width: 100%;
            padding: 10px;
            margin-bottom: 60px;

        }

        .box{
            background-color: #fac0a8;            ;
            height: fit-content;
            width: 60%;
            top:0px;
            border-radius: 40px;
            max-width: 700px;
            display: inline-block;
            overflow:visible;
            @include mobile{
                width: 80%;
                margin-left: 10%;
            }

            h2{
                margin-top: 25px;
                margin-left: 25px;
                overflow-wrap: normal;
                @include mobile{
                    font-size: 17px;
                }
            }

            p{
                font-size: 17px;
                text-indent: 40px;
                margin-left: 25px;
                margin-right: 25px;
                margin-bottom: 20px;
                overflow-wrap: normal;
                margin-top: 5px;
                
                @include mobile{
                    font-size: 10px;
                }
            }
            hr{
                margin-left: 20px;
                margin-right: 20px;
            }
            h3.published{
                margin-top: 5px;
                margin-left: 25px;
                @include mobile{
                    font-size: 13px;
                }
            }

            h3.role{
                margin-top: 5px;
                margin-left: 25px;
                @include mobile{
                    font-size: 13px;
                }
            }
            h3.tech{
                color: whitesmoke;
                bottom: 0px;
                margin-left: 30px;
                margin-bottom: 5px;
                margin-left: 30px;
                margin-top: 15px;
                @include mobile{
                    font-size: 13px;
                }
        
             span{
                 color: #a6586e;
             }   
            }
            h3.skills{
                color: whitesmoke;
                bottom: 0px;
                margin-left: 30px;
                margin-bottom: 25px;
                @include mobile{
                    font-size: 13px;
                }        
             span{
                 color: #a6586e;                 ;
             }   
            }
                

        }


        .imageSpace{
            right: 0px;
            height: 360px;
            width: 360px;
            top: 0px;
            margin-left: 10px;
            position: relative;
            overflow: hidden;
            @include mobile{
                display: none;
            }
            img{
                width: auto;
                height: auto;
                top:0px;
                border-radius: 20px;
                
            }

            .link{
                position: absolute;
                top:32%;
                left:33%;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                overflow: hidden;
                img{
                    width: auto;
                    height: auto;
                    opacity: 0;
                    overflow: hidden;
                }
                .link{
                    position: absolute;
                    top:23%;
                    left:61%;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: center;
                    overflow: hidden;
                    img{
                        width: auto;
                        height: auto;
                        opacity: 1;
                        overflow: hidden;
                    }
                }
            }
            

        }

        .imageSpace:hover{
            img{
                opacity: .7;
                z-index: 1;
            }
            .link{
                opacity: 1;
                z-index: 2;
            }
            
            

        }
    }

    .onetrust{
        width: 70%;
        height:70%;
        display: flex;
        flex-direction: row;
        padding: 15px;
        color:white;
        align-content: center;
        position: relative;
        overflow:visible;
        margin-bottom: 10px;

        @include mobile{
            width: 100%;
            padding: 10px;
            margin-bottom: 60px;
        }


        .box{
            background-color: rgb(44, 151, 29);
            height: fit-content;
            width: 60%;
            top:0px;
            border-radius: 40px;
            max-width: 700px;
            display: inline-block;
            overflow:visible;
            @include mobile{
                width: 80%;
                margin-left: 10%;
            }

            h2{
                margin-top: 25px;
                margin-left: 25px;
                overflow-wrap: normal;
                display: inline-block;
                @include mobile{
                    font-size: 17px;
                }
            }

            p{
                font-size: 17px;
                text-indent: 40px;
                margin-left: 25px;
                margin-right: 25px;
                margin-bottom: 10px;
                overflow-wrap: normal;
                margin-top: 5px;
                display: inline-block;
                @include mobile{
                    font-size: 10px;
                    margin-bottom: 10px;
                }
            }
            hr{
                margin-left: 20px;
                margin-right: 20px;

            }
            h3.published{
                margin-top: 5px;
                margin-left: 25px;
                @include mobile{
                    font-size: 13px;
                }
            }

            h3.role{
                margin-top: 5px;
                margin-left: 25px;
                @include mobile{
                    font-size: 13px;
                }
            }
            h3.tech{
                color: whitesmoke;
                bottom: 0px;
                margin-left: 30px;
                margin-bottom: 5px;
                margin-left: 30px;
                margin-top: 15px;
                display: inline-block;
                @include mobile{
                    font-size: 13px;
                    margin-top: 5px;
                    margin-bottom: 0px;
                }
        
             span{
                 color: #9AC791;

             }   
            }
            h3.skills{
                color: whitesmoke;
                bottom: 0px;
                margin-left: 30px;
                margin-bottom: 15px;
                display: inline-block;   
                @include mobile{
                    font-size: 13px;
                    margin-top: 5px;
                    margin-bottom: 10px;
                }     
             span{
                color: #9AC791;

            }   
            }
                

        }


        .imageSpace{
            right: 0px;
            height: 360px;
            width: 360px;
            top: 0px;
            margin-left: 10px;
            position: relative;
            overflow: hidden;
            @include mobile{
                display: none;
            }
            img{
                width: auto;
                height: auto;
                top:0px;
                border-radius: 40px;
                
            }

            .link{
                position: absolute;
                top:32%;
                left:33%;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                overflow: hidden;
                img{
                    width: auto;
                    height: auto;
                    opacity: 0;
                    overflow: hidden;
                }
                .link{
                    position: absolute;
                    top:23%;
                    left:61%;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: center;
                    overflow: hidden;
                    img{
                        width: auto;
                        height: auto;
                        opacity: 1;
                        overflow: hidden;
                    }
                }
            }
            

        }
        .imageSpace:hover{
            img{
                opacity: .7;
                z-index: 1;
            }
            .link{
                opacity: 1;
                z-index: 2;
            }

        }
    }

    .jpm{
        width: 70%;
        height:70%;
        display: flex;
        flex-direction: row;
        padding: 15px;
        color:white;
        align-content: center;
        position: relative;
        overflow:visible;
        margin-bottom: 10px;

        @include mobile{
            width: 100%;
            padding: 10px;
            margin-bottom: 60px;
        }


        .box{
            background-color: #656263;
            height: fit-content;
            width: 60%;
            top:0px;
            border-radius: 40px;
            max-width: 700px;
            display: inline-block;
            overflow:visible;
            @include mobile{
                width: 80%;
                margin-left: 10%;
            }

            h2{
                margin-top: 25px;
                margin-left: 25px;
                overflow-wrap: normal;
                display: inline-block;
                @include mobile{
                    font-size: 17px;
                }
            }

            p{
                font-size: 17px;
                text-indent: 40px;
                margin-left: 25px;
                margin-right: 25px;
                margin-bottom: 10px;
                overflow-wrap: normal;
                margin-top: 5px;
                display: inline-block;
                @include mobile{
                    font-size: 10px;
                    margin-bottom: 10px;
                }
            }
            hr{
                margin-left: 20px;
                margin-right: 20px;

            }
            h3.published{
                margin-top: 5px;
                margin-left: 25px;
                @include mobile{
                    font-size: 13px;
                }
            }

            h3.role{
                margin-top: 5px;
                margin-left: 25px;
                @include mobile{
                    font-size: 13px;
                }
            }
            h3.tech{
                color: whitesmoke;
                bottom: 0px;
                margin-left: 30px;
                margin-bottom: 5px;
                margin-left: 30px;
                margin-top: 15px;
                display: inline-block;
                @include mobile{
                    font-size: 13px;
                    margin-top: 5px;
                    margin-bottom: 0px;
                }
        
             span{
                 color: #231f20;

             }   
            }
            h3.skills{
                color: whitesmoke;
                bottom: 0px;
                margin-left: 30px;
                margin-bottom: 15px;
                display: inline-block;   
                @include mobile{
                    font-size: 13px;
                    margin-top: 5px;
                    margin-bottom: 10px;
                }     
             span{
                color: #231f20;

            }   
            }
                

        }


        .imageSpace{
            right: 0px;
            height: 360px;
            width: 360px;
            top: 0px;
            margin-left: 10px;
            position: relative;
            overflow: hidden;
            @include mobile{
                display: none;
            }
            img{
                width: auto;
                height: auto;
                top:0px;
                border-radius: 40px;
                
            }

            .link{
                position: absolute;
                top:32%;
                left:33%;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                overflow: hidden;
                img{
                    width: auto;
                    height: auto;
                    opacity: 0;
                    overflow: hidden;
                }
                .link{
                    position: absolute;
                    top:23%;
                    left:61%;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: center;
                    overflow: hidden;
                    img{
                        width: auto;
                        height: auto;
                        opacity: 1;
                        overflow: hidden;
                    }
                }
            }
            

        }
        .imageSpace:hover{
            img{
                opacity: .7;
                z-index: 1;
            }
            .link{
                opacity: 1;
                z-index: 2;
            }

        }
    }

    .twitter{
        width: 70%;
        height:70%;
        display: flex;
        flex-direction: row;
        //padding: 30px;
        color:white;
        align-content: center;
        position: relative;
        overflow:visible;
        margin-bottom: 30px;

        @include mobile{
            width: 100%;
            padding: 10px;
            margin-bottom: 60px;

        }

        .box{
            background-color: $mainColor;
            height: fit-content;
            width: 60%;
            top:0px;
            border-radius: 40px;
            max-width: 700px;
            display: inline-block;
            overflow:visible;
            @include mobile{
                width: 80%;
                margin-left: 10%;
            }

            h2{
                margin-top: 25px;
                margin-left: 25px;
                overflow-wrap: normal;
                @include mobile{
                    font-size: 17px;
                }
            }

            p{
                font-size: 17px;
                text-indent: 40px;
                margin-left: 25px;
                margin-right: 25px;
                margin-bottom: 20px;
                overflow-wrap: normal;
                margin-top: 5px;
                @include mobile{
                    font-size: 10px;
                }
            }
            hr{
                margin-left: 20px;
                margin-right: 20px;
            }
            h3.published{
                margin-top: 5px;
                margin-left: 25px;
                @include mobile{
                    font-size: 13px;
                }
            }

            h3.role{
                margin-top: 5px;
                margin-left: 25px;
                @include mobile{
                    font-size: 13px;
                }
            }
            h3.tech{
                color: whitesmoke;
                bottom: 0px;
                margin-left: 30px;
                margin-bottom: 5px;
                margin-left: 30px;
                margin-top: 15px;
                @include mobile{
                    font-size: 13px;
                }
        
             span{
                 color: lightgrey;
             }   
            }
            h3.skills{
                color: whitesmoke;
                bottom: 0px;
                margin-left: 30px;
                margin-bottom: 25px;
                @include mobile{
                    font-size: 13px;
                }        
             span{
                color: lightgrey;
            }   
            }
                

        }


        .imageSpace{
            right: 0px;
            height: 360px;
            width: 360px;
            top: 0px;
            margin-left: 10px;
            position: relative;
            overflow: hidden;
            @include mobile{
                display: none;
            }
            img{
                width: auto;
                height: auto;
                top:0px;
                border-radius: 50px;
                
            }

            .link{
                position: absolute;
                top:32%;
                left:33%;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                overflow: hidden;
                img{
                    width: auto;
                    height: auto;
                    opacity: 0;
                    overflow: hidden;
                }
                .link{
                    position: absolute;
                    top:23%;
                    left:61%;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: center;
                    overflow: hidden;
                    img{
                        width: auto;
                        height: auto;
                        opacity: 1;
                        overflow: hidden;
                    }
                }
            }
            

        }

        .imageSpace:hover{
            img{
                opacity: .7;
                z-index: 1;
            }
            .link{
                opacity: 1;
                z-index: 2;
            }

        }
    }

    .mnl{
        width: 70%;
        height:70%;
        display: flex;
        flex-direction: row;
       // padding: 30px;
        color:white;
        align-content: center;
        position: relative;
        overflow:visible;
        margin-bottom: 30px;

        @include mobile{
            width: 100%;
            padding: 10px;
            margin-bottom: 60px;

        }

        .box{
            background-color: #ffbe0d;
            height: fit-content;
            width: 60%;
            top:0px;
            border-radius: 40px;
            max-width: 700px;
            display: inline-block;
            overflow:visible;
            @include mobile{
                width: 80%;
                margin-left: 10%;
            }

            h2{
                margin-top: 25px;
                margin-left: 25px;
                overflow-wrap: normal;
                @include mobile{
                    font-size: 17px;
                }
            }

            p{
                font-size: 17px;
                text-indent: 40px;
                margin-left: 25px;
                margin-right: 25px;
                margin-bottom: 20px;
                overflow-wrap: normal;
                margin-top: 5px;
                @include mobile{
                    font-size: 10px;
                }
            }
            hr{
                margin-left: 20px;
                margin-right: 20px;
            }
            h3.published{
                margin-top: 5px;
                margin-left: 25px;
                @include mobile{
                    font-size: 13px;
                }
            }

            h3.role{
                margin-top: 5px;
                margin-left: 25px;
                @include mobile{
                    font-size: 13px;
                }
            }
            h3.tech{
                color: whitesmoke;
                bottom: 0px;
                margin-left: 30px;
                margin-bottom: 5px;
                margin-left: 30px;
                margin-top: 15px;
                @include mobile{
                    font-size: 13px;
                }
        
             span{
                 color: #008dd0;
             }   
            }
            h3.skills{
                color: whitesmoke;
                bottom: 0px;
                margin-left: 30px;
                margin-bottom: 25px;
                @include mobile{
                    font-size: 13px;
                }        
             span{
                //color: #005ca7;
                color: #008dd0;
            }   
            }
                

        }


        .imageSpace{
            right: 0px;
            height: 360px;
            width: 360px;
            top: 0px;
            margin-left: 10px;
            position: relative;
            overflow: hidden;
            @include mobile{
                display: none;
            }
            img{
                width: auto;
                height: auto;
                top:0px;
                border-radius: 20px;
                
            }

            .link{
                position: absolute;
                top:32%;
                left:33%;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                overflow: hidden;
                img{
                    width: auto;
                    height: auto;
                    opacity: 0;
                    overflow: hidden;
                }
                .link{
                    position: absolute;
                    top:23%;
                    left:61%;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: center;
                    overflow: hidden;
                    img{
                        width: auto;
                        height: auto;
                        opacity: 1;
                        overflow: hidden;
                    }
                }
            }
            

        }

        .imageSpace:hover{
            img{
                opacity: .7;
                z-index: 1;
            }
            .link{
                opacity: 1;
                z-index: 2;
            }

        }
    }
    .cslib{
        width: 70%;
        height:70%;
        display: flex;
        flex-direction: row;
       // padding: 30px;
        color:white;
        align-content: center;
        position: relative;
        overflow:visible;
        margin-bottom: 30px;

        @include mobile{
            width: 100%;
            padding: 10px;
            margin-bottom: 60px;

        }

        .box{
            background-color: #5AA4F8;
            height: fit-content;
            width: 60%;
            top:0px;
            border-radius: 40px;
            max-width: 700px;
            display: inline-block;
            overflow:visible;
            @include mobile{
                width: 80%;
                margin-left: 10%;
            }

            h2{
                margin-top: 25px;
                margin-left: 25px;
                overflow-wrap: normal;
                @include mobile{
                    font-size: 17px;
                }
            }

            p{
                font-size: 17px;
                text-indent: 40px;
                margin-left: 25px;
                margin-right: 25px;
                margin-bottom: 20px;
                overflow-wrap: normal;
                margin-top: 5px;
                @include mobile{
                    font-size: 10px;
                }
            }
            hr{
                margin-left: 20px;
                margin-right: 20px;
            }
            h3.published{
                margin-top: 5px;
                margin-left: 25px;
                @include mobile{
                    font-size: 13px;
                }
            }

            h3.role{
                margin-top: 5px;
                margin-left: 25px;
                @include mobile{
                    font-size: 13px;
                }
            }
            h3.tech{
                color: whitesmoke;
                bottom: 0px;
                margin-left: 30px;
                margin-bottom: 5px;
                margin-left: 30px;
                margin-top: 15px;
                @include mobile{
                    font-size: 13px;
                }
        
             span{
                 color: whitesmoke;
             }   
            }
            h3.skills{
                color: whitesmoke;
                bottom: 0px;
                margin-left: 30px;
                margin-bottom: 25px;
                @include mobile{
                    font-size: 13px;
                }        
             span{
                //color: #005ca7;
                color: whitesmoke;
            }   
            }
                

        }


        .imageSpace{
            right: 0px;
            height: 360px;
            width: 360px;
            top: 0px;
            margin-left: 10px;
            position: relative;
            overflow: hidden;
            @include mobile{
                display: none;
            }
            img{
                width: auto;
                height: auto;
                top:0px;
                border-radius: 20px;
                
            }

            .link{
                position: absolute;
                top:32%;
                left:33%;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                overflow: hidden;
                img{
                    width: auto;
                    height: auto;
                    opacity: 0;
                    overflow: hidden;
                }
                .link{
                    position: absolute;
                    top:23%;
                    left:61%;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: center;
                    overflow: hidden;
                    img{
                        width: auto;
                        height: auto;
                        opacity: 1;
                        overflow: hidden;
                    }
                }
            }
            

        }

        .imageSpace:hover{
            img{
                opacity: .7;
                z-index: 1;
            }
            .link{
                opacity: 1;
                z-index: 2;
            }

        }
    }
    .mit{
        width: 70%;
        height:70%;
        display: flex;
        flex-direction: row;
       // padding: 30px;
        color:white;
        align-content: center;
        position: relative;
        overflow:visible;
        margin-bottom: 30px;

        @include mobile{
            width: 100%;
            padding: 10px;
            margin-bottom: 60px;

        }

        .box{
            background-color: #993333;
            height: fit-content;
            width: 60%;
            top:0px;
            border-radius: 40px;
            max-width: 700px;
            display: inline-block;
            overflow:visible;
            @include mobile{
                width: 80%;
                margin-left: 10%;
            }

            h2{
                margin-top: 25px;
                margin-left: 25px;
                overflow-wrap: normal;
                @include mobile{
                    font-size: 17px;
                }
            }

            p{
                font-size: 17px;
                text-indent: 40px;
                margin-left: 25px;
                margin-right: 25px;
                margin-bottom: 20px;
                overflow-wrap: normal;
                margin-top: 5px;
                @include mobile{
                    font-size: 10px;
                }
            }
            hr{
                margin-left: 20px;
                margin-right: 20px;
            }
            h3.published{
                margin-top: 5px;
                margin-left: 25px;
                @include mobile{
                    font-size: 13px;
                }
            }

            h3.role{
                margin-top: 5px;
                margin-left: 25px;
                @include mobile{
                    font-size: 13px;
                }
            }
            h3.tech{
                color: whitesmoke;
                bottom: 0px;
                margin-left: 30px;
                margin-bottom: 5px;
                margin-left: 30px;
                margin-top: 15px;
                @include mobile{
                    font-size: 13px;
                }
        
             span{
                 color: #bbbbbb;
             }   
            }
            h3.skills{
                color: whitesmoke;
                bottom: 0px;
                margin-left: 30px;
                margin-bottom: 25px;
                @include mobile{
                    font-size: 13px;
                }        
             span{
                //color: #005ca7;
                color: #bbbbbb;
            }   
            }
                

        }


        .imageSpace{
            right: 0px;
            height: 360px;
            width: 360px;
            top: 0px;
            margin-left: 10px;
            position: relative;
            overflow: hidden;
            @include mobile{
                display: none;
            }
            img{
                width: auto;
                height: auto;
                top:0px;
                border-radius: 20px;
                
            }

            .link{
                position: absolute;
                top:32%;
                left:33%;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                overflow: hidden;
                img{
                    width: auto;
                    height: auto;
                    opacity: 0;
                    overflow: hidden;
                }
                .link{
                    position: absolute;
                    top:23%;
                    left:61%;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: center;
                    overflow: hidden;
                    img{
                        width: auto;
                        height: auto;
                        opacity: 1;
                        overflow: hidden;
                    }
                }
            }
            

        }

        .imageSpace:hover{
            img{
                opacity: .7;
                z-index: 1;
            }
            .link{
                opacity: 1;
                z-index: 2;
            }

        }
    }
    .anagrams{
        width: 70%;
        height:70%;
        display: flex;
        flex-direction: row;
        padding: 30px;
        color:white;        ;
        align-content: center;
        position: relative;
        overflow:visible;

        @include mobile{
            width: 100%;
            padding: 10px;
            margin-bottom: 60px;

        }

        .box{
            background-color: #ebbc72;
            height: fit-content;
            width: 60%;
            top:0px;
            border-radius: 40px;
            max-width: 700px;
            display: inline-block;
            overflow:visible;
            @include mobile{
                width: 80%;
                margin-left: 10%;
            }

            h2{
                margin-top: 25px;
                margin-left: 25px;
                overflow-wrap: normal;
                @include mobile{
                    font-size: 17px;
                }
            }

            p{
                font-size: 17px;
                text-indent: 40px;
                margin-left: 25px;
                margin-right: 25px;
                margin-bottom: 20px;
                overflow-wrap: normal;
                margin-top: 5px;
                
                @include mobile{
                    font-size: 10px;
                }
            }
            hr{
                margin-left: 20px;
                margin-right: 20px;
            }
            h3.published{
                margin-top: 5px;
                margin-left: 25px;
                @include mobile{
                    font-size: 13px;
                }
            }

            h3.role{
                margin-top: 5px;
                margin-left: 25px;
                @include mobile{
                    font-size: 13px;
                }
            }
            h3.tech{
                color: whitesmoke;
                bottom: 0px;
                margin-left: 30px;
                margin-bottom: 5px;
                margin-left: 30px;
                margin-top: 15px;
                @include mobile{
                    font-size: 13px;
                }
        
             span{
                 color: #055a8c;
             }   
            }
            h3.skills{
                color: whitesmoke;
                bottom: 0px;
                margin-left: 30px;
                margin-bottom: 25px;
                @include mobile{
                    font-size: 13px;
                }        
             span{
                 color: #055a8c;                 ;
             }   
            }
                

        }


        .imageSpace{
            right: 0px;
            height: 360px;
            width: 360px;
            top: 0px;
            margin-left: 10px;
            position: relative;
            overflow: hidden;
            @include mobile{
                display: none;
            }
            img{
                width: auto;
                height: auto;
                top:0px;
                border-radius: 20px;
                
            }

            .link{
                position: absolute;
                top:32%;
                left:28%;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                overflow: hidden;
                img{
                    width: auto;
                    height: auto;
                    opacity: 0;
                    overflow: hidden;
                }
                .link{
                    position: absolute;
                    top:23%;
                    left:45%;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: center;
                    overflow: hidden;
                    img{
                        width: auto;
                        height: auto;
                        opacity: 1;
                        overflow: hidden;
                    }
                }
            }
            

        }

        .imageSpace:hover{
            img{
                opacity: .7;
                z-index: 1;
            }
            .link{
                opacity: 1;
                z-index: 2;
            }

        }
    }
    .skills{
        overflow: hidden;
        a{
            bottom: 10px;
            vertical-align: middle;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            text-decoration: none;
            color: black;
            left: 25%;
            overflow: hidden;

    
            img{
                width: 90px;
                overflow: hidden;
            }
    
    
            li{
                list-style: none;
                overflow: hidden;
            }
        }
    }
}